import { useState, useEffect, useRef } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import Modal from 'react-bootstrap/Modal'

import { AnimationOnScroll } from 'react-animation-on-scroll';

import ToolsSisesa from './ToolsSisesa'

import "./styles.css";

import service01 from './img/home/service01.jpg'
import service02 from './img/home/service02.jpg'
import service03 from './img/home/service03.jpg'
import service04 from './img/home/service04.jpg'
import service05 from './img/home/service05.jpg'

import reemplazo1 from './img/home/reemplazo1.jpg'
import reemplazo2 from './img/home/reemplazo2.jpg'
import reemplazo3 from './img/home/reemplazo3.jpg'
import reemplazo4 from './img/home/reemplazo4.jpg'
import reemplazo5 from './img/home/reemplazo5.jpg'
import reemplazo6 from './img/home/reemplazo6.jpg'

import 'animate.css';
import './App.css';

function Services() {
  const [ service, setService ] = useState('')
  const [ showReemplazo, setShowReemplazo ] = useState(false)

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const serviceVal = urlParams.get('s')

    if (serviceVal == 'ree') setShowReemplazo(true)

    setService(serviceVal)
  }, []);

  return (
    <div>
      <Modal
        size="lg"
        show={showReemplazo}
        onHide={() => setShowReemplazo(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body className="body-service-modal">
          <div className="section-title" align="center">SERVICIO</div>
          <br/>
          <div className='mission-title'>
            Reemplazo de elementos estructurales (de madera tratada o fibra de vidrio)
          </div>
          <br/>
          <div className='mission-desc'>
            Para modernizar su Torre de Enfriamiento existente, realizamos la sustitución de estructura de Madera tratada químicamente por Fibra de Vidrio Reforzado (FRP), ya que tiene mejores características físico-mecánicos y una vida útil más larga.
          </div>
          <br/>
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100" src={ reemplazo1 } />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ reemplazo2 } />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ reemplazo3 } />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ reemplazo4 } />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ reemplazo5 } />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ reemplazo6 } />
            </Carousel.Item>
          </Carousel>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowReemplazo(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>


      <div className='navbar-space'></div>

      <div className="animate__animated animate__bounce animate__fadeIn">
        <Container className="section" style={{ paddingTop: '40px', paddingBottom: '10px'  }}>
          <Row>
            <div className="section-title" align="center">SERVICIOS QUE OFRECEMOS</div>
          </Row>
          <br/>
          <Row className="align-items-center">
            <Col md={ 6 } xs={ 12 }>            
              <div className='section-desc' style={{ textAlign: 'left' }}>
                <ul>
                  <li>Suministro de Torres de Enfriamiento con eficiencia térmica, garantizada por escrito de acuerdo con las necesidades del cliente.</li>
                  <li>Optimización de enfriamiento de Torres existentes.</li>
                  <li>Selección y asesoría de relleno de acuerdo con el tipo de proceso.</li>
                  <li><a href="/servicios?s=ree">Reemplazo de elementos estructurales (de madera tratada o fibra de vidrio).</a></li>
                  <li>Suministro de refacciones para Torres de todas las marcas.</li>
                  <li>Pruebas de eficiencia térmica y arrastre.</li>
                  <li>Simulaciones de comportamiento térmico para incremento de capacidad y asesoría.</li>
                </ul>
              </div>
            </Col>
            <Col md={ 6 } xs={ 12 }>
              <div className="section-desc">
                <img src={ service05 } className='us-img'/>
              </div>
            </Col>
            {/*<Col md={ 6 } xs={ 12 }>
              <div className="section-desc">
                <img src={ nosotros } className='us-img'/>
              </div>
            </Col>*/}
          </Row>
          <Row className="align-items-center">
            <Col>
            </Col>
          </Row>
        </Container>

        <div className='animate__animated animate__bounce animate__fadeIn'>
        <Container>
          <Row>
            <Col md={ 6 } className="pb-4">
                <div className="service-desc">
                  <img src={ service01 } className='us-img'/>
                </div>
            </Col>
            <Col md={ 6 } className="pb-4">
                <div className="service-desc">
                  <img src={ service02 } className='us-img'/>
                </div>
            </Col>
            <Col md={ 6 } className="pb-4">
                <div className="service-desc">
                  <img src={ service03 } className='us-img'/>
                </div>
            </Col>
            <Col md={ 6 } className="pb-4">
                <div className="service-desc">
                  <img src={ service04 } className='us-img'/>
                </div>
            </Col>
          </Row>
          <br/>
        </Container>
        </div>

        <ToolsSisesa />

      </div>

    </div>
  );
}

export default Services;
