import { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player'
import axios from 'axios'
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ToolsSisesa from './ToolsSisesa'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'

import { AnimationOnScroll } from 'react-animation-on-scroll';
//import AnimatedText from 'react-animated-text-content';

import { Facebook, Instagram, Linkedin, Tiktok, Whatsapp, Youtube } from 'react-bootstrap-icons';

import "./styles.css";

import logo from './img/home/logo.png'
import logo_b from './img/home/logo_b.png'
import sisesaBanner from './img/home/sisesa.jpg'
import relleno from './img/home/rellenos.png'
import eliminador from './img/home/eliminador.png'
import mecanico from './img/home/mecanico.png'
import madera from './img/home/madera.png'
import servicio1 from './img/home/servicio_1.jpg'
import servicio2 from './img/home/servicio_2.jpg'
import servicio3 from './img/home/servicio_3.jpg'


import 'animate.css';
import './App.css';

const API_PATH = 'https://residencialcardon.com/mailer.php';
const SITE_KEY = "6LeAxHwiAAAAALLbjz3MEJLq8XvkOQumrvHxfWIv";

const containerStyle = {
  width: '100%',
  height: '350px'
};

const center = {
  lat: 20.9289936,
  lng: -100.7366122
};

const centerMark = {
  lat: 20.9350462,
  lng: -100.7360792
};

function Home() {
  return (
    <div>
      <div className='navbar-space'></div>

      <Container className="home-section">
        <Row className="align-items-center">
          <ReactPlayer className="player-v" url='https://youtu.be/NlCSxuI3fPM' playing={ true } loop={ true } volume={ 0 } muted={ true } controls={ true }/>
        </Row>
      </Container>

      <Container className="section" style={{ paddingTop: '100px'  }}>
        <Row>
          <div className="section-title" align="center">NOSOTROS</div>
        </Row>
        <Row>
          <Col md={ 12 } xs={ 12 }>            
            <div className="section-desc">
              <div>SOMOS UNA EMPRESA MEXICANA QUE CUENTA CON OFICINAS EN MÉXICO Y ESTADOS UNIDOS ESPECIALIZADA EN EL DISEÑO, FABRICACIÓN Y CONSTRUCCIÓN DE TORRES DE ENFRIAMIENTO INDUSTRIALES.</div>
              <br/>
              <div>Contamos con personal de ingeniería para el diseño y simulación de proyectos que requieren intercambio de calor. Somos miembros del Cooling Technology Institute (CTI) en donde nuestro personal es capacitado cada año y cuenta con su respaldo y asesoría.</div>
            </div>
          </Col>
        </Row>
      </Container>

      <ToolsSisesa />

      <div className="section container-services">
        <Container className="section-services">
        <br/>
        <Row>
          <div>
            <div className="section-title" align="center" >SERVICIOS</div>
          </div>
        </Row>
        <br/><br/>

        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn animateOnce" animateOut="animate__fadeOut">
        <Row className='card-row'>
          <Col md={ 4 } xs={ 12 } className='col-md-3-ext'>
            <Card className="card-service">
              <Card.Img variant="top" className='card-service-img' src={ servicio1 } />
              <Card.Body align='center'>
                <Card.Title className="service-title">FABRICACIÓN Y MANUFACTURA DE PARTES</Card.Title>
              </Card.Body>
            </Card>
          </Col>

          <Col md={ 4 } xs={ 12 } className='col-md-3-ext'>
            <Card className="card-service">
              <Card.Img variant="top" className='card-service-img' src={ servicio2 } />
              <Card.Body align='center'>
                <Card.Title className="service-title">MONTAJE, REPARACIÓN Y MANTENIMIENTO</Card.Title>
              </Card.Body>
            </Card>
          </Col>

          <Col md={ 4 } xs={ 12 } className='col-md-3-ext'>
            <Card className="card-service">
              <Card.Img variant="top" className='card-service-img' src={ servicio3 } />
              <Card.Body align='center'>
                <Card.Title className="service-title">ASISTENCIA EN PRUEBAS DE EFICIENCIA TÉRMICA</Card.Title>
              </Card.Body>
            </Card>
          </Col>

        </Row>
        </AnimationOnScroll>

        <Row>
          <Col>
          <br/>
            <div align="center">
              <a href="/servicios"><Button variant="warning">Ver más servicios</Button></a>{' '}
            </div>
          </Col>
        </Row>

        <br/><br/>
        </Container>
      </div>

    </div>
  );
}

export default Home;
