import { useState, useEffect, useRef } from 'react';
import axios from 'axios'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import contact from './img/home/contact.png'

import { EnvelopeFill, Facebook, Instagram, Linkedin, Phone, PhoneFill, Telephone, TelephoneFill, Tiktok, Whatsapp, Youtube } from 'react-bootstrap-icons';

import "./styles.css";
import 'animate.css';
import './App.css';
import { Geo } from 'react-bootstrap-icons';

function Contact() {
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [comments, setComments] = useState("");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  const [ faceLink, setFaceLink ] = useState('https://www.facebook.com/sisesacooling')
  const [ instaLink, setInstaLink ] = useState('https://www.instagram.com/sisesacooling/')
  const [ linkedLink, setLinkedLink ] = useState('https://www.linkedin.com/in/sisesa-torres-de-enfriamiento-b1364957/')
  const [ youtubeLink, setYoutubeLink ] = useState('https://www.youtube.com/@Sisesacooling')

  const handleShow = () => setShow(true);

  const handleOnChange = (event) => {
    var inputName = "set" + event.target.name.charAt(0).toUpperCase() + event.target.name.slice(1);
    eval(inputName)(event.target.value)
  }

  useEffect(() => {
  });

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=6LfLq5omAAAAADTB0D9ibhfN2_t1eVwFsyn_Qtwm`, function () {
      console.log("Script loaded!");
    });
  }, []);

  const handleOnSubmit = (event) => {
    event.preventDefault();
    
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute("6LfLq5omAAAAADTB0D9ibhfN2_t1eVwFsyn_Qtwm", { action: 'submit' }).then(token => {
        //if(isVerified){
        setLoading(true);
        //const recaptchaValue = recaptchaRef.current.getValue();
        var form = document.getElementById("contactForm");
        var formData = new FormData(form);
        formData.append('recaptcha_response', token);

        setLoading(true);
        axios.post("https://mgpublicidad.mx/mailer_sisesa.php", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
          }).then(function(response) {

            if(response.data == "FAIL" || response.data == "ERROR") {
              setLoading(false)
              setSuccess(false)
              handleShow()
            } else {
              setLoading(false)
              setSuccess(true)
              handleShow()

              setName("");
              setPhone("");
              setEmail("");
              setComments("");
              setCompany("");
            }

          }).catch(function(err) {
            setLoading(false)
            setSuccess(false)
            handleShow()
          });
      //} else 
        //alert("Por favor, verifica que no eres un robot")
      });
    });
  }

  return (
    <div>
      <Modal show={show} onHide={ () => setShow(false) }>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className={success ? '' : 'hide'}>Mensaje Enviado</div>
            <div className={success == false ? '' : 'hide'}>Error</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={success ? '' : 'hide'} align="center">
            Tu mensaje ha sido enviado. En breve nos comunicaremos contigo. Gracias.
          </div>
          <div className={success == false ? '' : 'hide'} align="center">Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={ () => setShow(false) }>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className='navbar-space'></div>

      <div className="animate__animated animate__bounce animate__fadeIn">
        <Container className="section" style={{ paddingBottom: '0px' }}>
          <Row>
            <div className="section-title" align="center">CONTÁCTANOS</div>
            <div className="" align="center">
              <div>Atención al cliente 24/7 los 365 días del año. <br/>Deja tus equipos en manos de expertos.</div>
            </div>
          </Row>
          <br/>
          <br/>
          <Form id="contactForm" onSubmit={handleOnSubmit}>
            <Row>
              <Col md={ 6 } xs={ 12 }>
                <div className="col-md-11 col-sm-11 col-xs-12 contact-info" style={{textAlign: "left"}}>
                  <br/>
                  <div className="ln_solid"></div>
                  <div className="padding-el">
                    <Row className="row-contact">
                      <Col xs={2} md={1}>
                        <TelephoneFill className='contact-img' />
                      </Col>
                      <Col xs={9} md={11}>
                        <div style={{ color: '#000' }}><a href="tel:5563857799" style={{ color: '#000' }}>+(52) 55 6385 7799</a> y <a href="tel:5563857708" style={{ color: '#000' }}>+(52) 55 6385 7708</a></div>
                      </Col>
                    </Row>
                  </div>
                  
                  <div className="ln_solid"></div>
                  <div className="padding-el">
                    <Row className="row-contact">
                      <Col xs={2} md={1}>
                        <Whatsapp className='contact-img' />
                      </Col>
                      <Col xs={9} md={11}>
                        <div style={{ color: '#000' }}><a href="https://wa.me/5215559656450" style={{ color: '#000' }}>55 5965 6450</a> y <a href="https://wa.me/5215566942346" style={{ color: '#000' }}>55 6694 2346</a></div>
                      </Col>
                    </Row>
                  </div>

                  <div className="ln_solid"></div>
                  <div className="padding-el"><a href="mailTo:ventas@sisesa.com">
                    <Row className="row-contact">
                      <Col xs={2} md={1}>
                        <EnvelopeFill className='contact-img' />
                      </Col>
                      <Col xs={9} md={11}>
                        <div style={{ color: '#000' }}>ventas@sisesa.com</div>
                      </Col>
                    </Row>
                  </a></div>

                  <div className="ln_space"></div>
                  <br/>
                  <br/>
                  <div>
                    <div>Síguenos en nuestras redes sociales</div>
                    <div>
                      <Linkedin className='icon-social' onClick={ () => window.open( linkedLink ) }/>&nbsp;&nbsp;&nbsp;&nbsp;
                      <Instagram className='icon-social' onClick={ () => window.open( instaLink ) }/>&nbsp;&nbsp;&nbsp;&nbsp;
                      {/* <Facebook className='icon-social' onClick={ () => window.open( faceLink ) }/>&nbsp;&nbsp;&nbsp;&nbsp;
                      <Youtube className='icon-social' onClick={ () => window.open( youtubeLink ) }/> */}
                    </div>
                  </div>
                </div>
                <br/>
                <div className="col-md-11 col-sm-11 col-xs-12" style={{textAlign: "left"}}>
                  <div className="mission-title" style={{ color: '#000' }} >Solicita tu cotización</div>
                  <br/>
                  <Form.Group controlId="formGridEmail" className='mb-3'>
                    <Form.Control required name="name" value={name} placeholder="Nombre:" onChange={handleOnChange}/>
                  </Form.Group>

                  <Form.Group controlId="formGridEmail" className='mb-3'>
                    <Form.Control required type="number" value={phone} name="phone" placeholder="Teléfono:" onChange={handleOnChange}/>
                  </Form.Group>

                  <Form.Group controlId="formGridEmail" className='mb-3'>
                    <Form.Control required type="email" value={email} name="email" placeholder="Email:" onChange={handleOnChange}/>
                  </Form.Group>

                  <Form.Group controlId="formGridEmail" className='mb-3'>
                    <Form.Control type="text" value={company} name="company" placeholder="Empresa:" onChange={handleOnChange}/>
                  </Form.Group>

                  <Form.Group controlId="formGridEmail" className='mb-3'>
                    <Form.Control required name="comments" as="textarea" value={comments} rows="4" placeholder="Comentarios:" onChange={handleOnChange}/>
                  </Form.Group>
                  <br/>

                  <Button disabled={isLoading} variant="warning" type="submit" className="float-right">{isLoading ? 'Enviando…' : 'ENVIAR'}</Button>
                </div>
              </Col>
              <Col md={ 6 } xs={ 12 }>
                <img src={ contact } className='contact-bg' />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>

    </div>
  );
}

export default Contact;
