import { useState, useEffect, useRef } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import "./styles.css";

import nosotros from './img/home/nosotros.jpg'
import nosotros2 from './img/home/nosotros2.jpg'
import nosotros3 from './img/home/nosotros3.jpg'


import 'animate.css';
import './App.css';

function Us() {
  return (
    <div>
      <div className='navbar-space'></div>

      <div className="animate__animated animate__bounce animate__fadeIn">
        <Container className="section" style={{ paddingTop: '40px'  }}>
          <Row>
            <div className="section-title" align="center">NOSOTROS</div>
          </Row>
          <br/>
          <Row className="align-items-center">
            <Col md={ 6 } xs={ 12 }>            
              <div className="section-desc">
                <img src={ nosotros } className='us-img'/>
              </div>
            </Col>
            <Col md={ 6 } xs={ 12 }>            
              <div className="grl-text" style={{ textAlign: 'justify' }}>
                <div><span className="resalt">SISESA</span>, es una empresa especializada en el diseño, fabricación y construcción de torres de enfriamiento industriales. Somos miembros del <span className="resalt">Cooling Technology Institute (CTI)</span>, en donde nuestro personal es actualizado anualmente sobre las mejoras tecnológicas y nuevos estándares en la industria.</div>
                <br/>
                <div>Contamos con mano de obra calificada y un importante stock de refacciones, disponibles para entrega inmediata.</div>
                <br/>
                <div>Además de una garantía de 12 meses en cualquiera de nuestros suministros.</div>
                <br/>
                <div>Realizamos la optimización de los equipos consiguiendo el máximo enfriamiento posible, <span className="resalt">economizando el ahorro de energía, disminuyendo las pérdidas de agua y prolongando la vida útil de los equipos.</span></div>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={ 6 } xs={ 12 }>            
              <div className="grl-text" style={{ textAlign: 'justify' }}>
                <div>En SISESA creemos que la entrega de un excelente trabajo es la mejor manera de agradecer la preferencia de un cliente.</div>
                <br/>
                <div>Damos servicio a empresas nacionales e internacionales de Generación Eléctrica, Refinerías, Petroquímicas, Metalúrgicas, Ingenios Azucareros, Plantas Farmacéuticas, Cerveceras y Refresqueras entre otras.</div>
                <br/>
                <div>También realizamos la supervisión en instalación de torres de enfriamiento de compañías internacionales que buscan calidad en mano de obra y un correcto monitoreo de los proyectos fuera de sus países de origen.</div>
                <div>Nuestro departamento de ingeniería cuenta con más de <span className="resalt">25 años de experiencia, en el diseño y fabricación de torres de enfriamiento.</span></div>
              </div>
            </Col>
            <Col md={ 6 } xs={ 12 }>            
              <div className="section-desc">
                <img src={ nosotros3 } className='us-img'/>
              </div>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col className="col-md-3-ext" md={ 4 } xs={ 12 }>
              <Card className="mission-card">
                <Card.Body>
                  <div className='mission-title'>
                    MISIÓN
                  </div>
                  <br/>
                  <div className='mission-desc'>
                    Mejorar la productividad de nuestros clientes dando soluciones integrales de enfriamiento industrial, realizando un servicio de alta calidad, rapidez y seguridad que proporcione plena satisfacción a nuestros usuarios, seguridad y bienestar de nuestros empleados, y que sea amable y responsable con el medio ambiente.
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col className="col-md-3-ext"  md={ 4 } xs={ 12 }>
              <Card className="mission-card" style={{ background: '#f59320' }}>
                <Card.Body>
                  <div className='mission-title' style={{ color: '#fff' }}>
                    VISIÓN
                  </div>
                  <br/>
                  <div className='mission-desc' style={{ color: '#fff' }}>
                    Ser una empresa reconocida entre las mejores en nuestra especialidad a nivel mundial por la rapidez en la solución de problemas, la calidad de sus materiales y servicios, su competitividad, seguridad y responsabilidad con el medio ambiente y por su amable atención al cliente.
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col className="col-md-3-ext"  md={ 4 } xs={ 12 }>
              <Card className="mission-card">
                <Card.Body>
                  <div className='mission-title'>
                  VALORES
                  </div>
                  <br/>
                  <div className='mission-desc'>
                    Contamos con personal de ingeniería para el diseño y simulación de proyectos que requieren intercambio de calor. Somos miembros del Cooling Technology Institute (CTI) en donde nuestro personal es capacitado cada año y cuenta con su respaldo y asesoría.
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    </div>
  );
}

export default Us;
