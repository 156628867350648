import { useState, useEffect, useRef } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'

import Slider from "react-slick";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

import { general_tools } from './tools'

import nosotros from './img/home/nosotros.jpg'
import nosotros2 from './img/home/nosotros2.jpg'

import relleno from './img/home/rellenos.png'
import eliminador from './img/home/eliminador.png'
import mecanico from './img/home/mecanico.png'
import madera from './img/home/madera.png'

import towers01 from './img/home/towers01.jpg'
import towers02 from './img/home/towers02.jpg'
import towers03 from './img/home/towers03.jpg'
import towers04 from './img/home/towers04.jpg'

import frp1 from './img/refacciones/frp1.jpg'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";
import 'animate.css';
import './App.css';

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: isMobile ? 2 : 4 ,
  slidesToScroll: 1,
  arrows: true,
  autoplaySpeed: 3000,
  autoplay: true,
  touchMove: true
};

function ToolsSisesa() {
  return (
    <div style={{ backgroundColor: '#EDEDED' }}>
      <div className="section container-depts">
        <Container className="section-depts">
        <br/>
        <Row>
          <div>
            <div className="section-title" align="center" style={ { color: '#464749' } }>REFACCIONES</div>
          </div>
        </Row>
        <br/><br/>

        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn animateOnce" animateOut="animate__fadeOut">
          {/*<Slider {...settings}>
            <div className='carusel-card'>
              <Card className="card-custom">
                <Card.Img variant="top" className='card-img' src={ relleno } />
                <Card.Body align='center'>
                  <Card.Title>Perfiles de Fibra de Vidrio</Card.Title>
                  <Card.Text>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className='carusel-card'>
              <Card className="card-custom">
                <Card.Img variant="top" className='card-img' src={ eliminador } />
                <Card.Body align='center'>
                  <Card.Title>Madera tratada químicamente</Card.Title>
                  <Card.Text>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className='carusel-card'>
              <Card className="card-custom">
                <Card.Img variant="top" className='card-img' src={ mecanico } />
                <Card.Body align='center'>
                  <Card.Title>Equipos mecánicos: Ventiladores, Flechas, Motores, Reductores, Soportes mecánicos y Flechas de Transmision.</Card.Title>
                  <Card.Text>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className='carusel-card'>
              <Card className="card-custom">
                <Card.Img variant="top" className='card-img' src={ madera } />
                <Card.Body align='center'>
                  <Card.Title>Rellenos</Card.Title>
                  <Card.Text>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className='carusel-card'>
              <Card className="card-custom">
                <Card.Img variant="top" className='card-img' src={ relleno } />
                <Card.Body align='center'>
                  <Card.Title>Eliminadores</Card.Title>
                  <Card.Text>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className='carusel-card'>
              <Card className="card-custom">
                <Card.Img variant="top" className='card-img' src={ eliminador } />
                <Card.Body align='center'>
                  <Card.Title>Tuberias de fibra de vidrio y acero al carbón</Card.Title>
                  <Card.Text>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
  </Slider>*/}

        <Container>
          <Row>
            <Slider {...settings}>
              { general_tools.map(item => (
                <>
                  <div>
                    <Carousel controls={ false } indicators={ false } pause={ false } fade={ true }>
                      {item.images.map(ig => (
                        <Carousel.Item>
                          <img className='grid-img' src={ ig } />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>

                  <div className='title-item'>{ item.name }</div>
                  <div className='category-item'>{ item.category }</div>
                </>  
              ))}
            </Slider>
          </Row>
        </Container>

        <br/>
        <Row>
          
          <Col>
          <br/>
            <div align="center">
              <a href='/refacciones'><Button variant="warning">Ver más refacciones</Button>{' '}</a>
            </div>
          </Col>
        </Row>

        {/*<Row className='card-row'>
          <Col md={ 4 } xs={ 12 } className='col-md-3-ext'>
            <Card className="card-custom">
              <Card.Img variant="top" className='card-img' src={ relleno } />
              <Card.Body align='left'>
                <Card.Title>Rellenos</Card.Title>
                <Card.Text>
                  Some Text
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={ 4 } xs={ 12 } className='col-md-3-ext'>
            <Card className="card-custom">
              <Card.Img variant="top" className='card-img' src={ eliminador } />
              <Card.Body align='left'>
                <Card.Title>Eliminador</Card.Title>
                <Card.Text>
                  Some Text
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={ 4 } xs={ 12 } className='col-md-3-ext'>
            <Card className="card-custom">
              <Card.Img variant="top" className='card-img' src={ mecanico } />
              <Card.Body align='left'>
                <Card.Title>Equipos mecánicos</Card.Title>
                <Card.Text>
                  Some Text
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          *
          <Col md={ 3 } xs={ 12 } className='col-md-3-ext'>
            <Card className="card-custom">
              <Card.Img variant="top" className='card-img' src={ madera } />
              <Card.Body align='left'>
                <Card.Title>Madera tratada para estructura</Card.Title>
                <Card.Text>
                  Some Text
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>*
        </Row>*/}
        
        </AnimationOnScroll>

        <br/><br/>
        </Container>
      </div>
    </div>
  );
}

export default ToolsSisesa;
