import { useState, useEffect, useRef } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
//import Gallery from "react-photo-gallery"
import PhotoAlbum from "react-photo-album";
import Carousel from 'react-bootstrap/Carousel'
import Modal from 'react-bootstrap/Modal'

import "./styles.css";
import 'animate.css';
import './App.css';

const photos = [
  {
    src: 'https://www.sisesa.com/gallery/gallery001.png',
    width: 628,
    height: 474,
    title: 'Ingenio Panuco'
  },
  {
    src: 'https://www.sisesa.com/gallery/gallery002.png',
    width: 1682,
    height: 1504,
    title: 'CFE Francisco Villa'
  },
  {
    src: 'https://www.sisesa.com/gallery/gallery003.png',
    width: 802,
    height: 630,
    title: 'CFE Humeros'
  },
  {
    src: 'https://www.sisesa.com/gallery/gallery004.png',
    width: 1280,
    height: 1080,
    title: 'Ingenio el Potrero'
  },
  {
    src: 'https://www.sisesa.com/gallery/gallery005.png',
    width: 555,
    height: 576,
    title: 'Ingenio el Mante'
  },
  {
    src: 'https://www.sisesa.com/gallery/gallery006.png',
    width: 819,
    height: 702,
    title: 'Ingenio San Cristobal'
  },
  {
    src: 'https://www.sisesa.com/gallery/gallery007.png',
    width: 1412,
    height: 900,
    title: 'CFE Cerro Prieto'
  },
  {
    src: 'https://www.sisesa.com/gallery/gallery008.png',
    width: 1075,
    height: 492,
    title: 'CFE Villa de Reyes'
  },
  {
    src: 'https://www.sisesa.com/gallery/gallery009.png',
    width: 1266,
    height: 397,
    title: 'Ingenio Pujiltic'
  },
  {
    src: 'https://www.sisesa.com/gallery/gallery010.png',
    width: 648,
    height: 483,
    title: 'CFE Zimapan'
  },
  {
    src: 'https://www.sisesa.com/gallery/gallery012.png',
    width: 803,
    height: 414,
    title: 'CFE Emilio Portes Gil (Rio Bravo)'
  },
  {
    src: 'https://www.sisesa.com/gallery/gallery013.png',
    width: 646,
    height: 585,
    title: 'Ingenio Pujiltic-Planta de Alcohol'
  },
  {
    src: 'https://www.sisesa.com/gallery/gallery014.png',
    width: 621,
    height: 296,
    title: 'Mexichem Resinas Vinilicas, Planta Altamira'
  }
];

function Galeria() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <Modal show={viewerIsOpen} onHide={closeLightbox} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{ background: "transparent" }}>
        <Carousel defaultActiveIndex={currentImage} interval={ null }>
        { photos.map((item, idx) => (
          <Carousel.Item align="center">
            <img
              className="carousel-item-img"
              src={item.src}
            />
          </Carousel.Item>
        ))}
        </Carousel>
      </Modal>

      <div className='navbar-space'></div>

      <div className="animate__animated animate__bounce animate__fadeIn">
        <Container className="section" style={{ paddingTop: '40px'  }}>
          <Row>
            <div className="section-title" align="center">GALERÍA</div>
          </Row>
          <br/>
          <br/>
          <Row>
            {/*<Gallery photos={photos} onClick={openLightbox}/>*/}
            <PhotoAlbum layout="rows" photos={photos}
              renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                <div style={{ position: "relative", ...wrapperStyle }}>
                    {renderDefaultPhoto({ wrapped: true })}
                    {photo.title && (
                      <div style={{ textTransform: "uppercase", padding: '15px 0' }} className="title-item">
                          { photo.title }
                      </div>
                    )}
                </div>
            )}
            />;
          </Row>
        </Container>
      </div>

    </div>
  );
}

export default Galeria;
