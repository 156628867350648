import { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player'
import axios from 'axios'
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './Home'
import Us from './Us'
import Towers from './Towers'
import Services from './Services'
import Contact from './Contact'
import Galeria from './Galeria'
import Spareparts from './Spareparts'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'

import { AnimationOnScroll } from 'react-animation-on-scroll';
//import AnimatedText from 'react-animated-text-content';

import { Facebook, Instagram, Linkedin, TelephoneFill, Whatsapp, Youtube } from 'react-bootstrap-icons';

import "./styles.css";

import logo from './img/home/logo.png'
import cert from './img/home/cert_cti.png'
import logo_b from './img/home/logo_b.png'
import sisesaBanner from './img/home/sisesa.jpg'
import relleno from './img/home/rellenos.png'
import eliminador from './img/home/eliminador.png'
import mecanico from './img/home/mecanico.png'
import madera from './img/home/madera.png'
import servicio1 from './img/home/servicio_1.jpg'
import servicio2 from './img/home/servicio_2.jpg'
import servicio3 from './img/home/servicio_3.jpg'


import 'animate.css';
import './App.css';

const API_PATH = 'https://residencialcardon.com/mailer.php';
const SITE_KEY = "6LeAxHwiAAAAALLbjz3MEJLq8XvkOQumrvHxfWIv";

const containerStyle = {
  width: '100%',
  height: '350px'
};

const center = {
  lat: 20.9289936,
  lng: -100.7366122
};

const centerMark = {
  lat: 20.9350462,
  lng: -100.7360792
};

function App() {
  const [showMessage, setShowMessage] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showFloatNav, setShowFloatNav] = useState(false)
  
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");

  const [ faceLink, setFaceLink ] = useState('https://www.facebook.com/sisesacooling')
  const [ instaLink, setInstaLink ] = useState('https://www.instagram.com/sisesacooling/')
  const [ linkedLink, setLinkedLink ] = useState('https://www.linkedin.com/in/sisesa-torres-de-enfriamiento-b1364957/')
  const [ youtubeLink, setYoutubeLink ] = useState('https://www.youtube.com/@Sisesacooling')

  const [ goingUp, setGoingUp ] = useState(false);
  const prevScrollY = useRef(0);

  useEffect(() => {
    if ( isMobile ) {
      if( isAndroid ) {
        //setFaceLink('fb://page/111933257823824')
      }
      else if( isIOS ) {
        //setFaceLink('fb://profile/111933257823824')
      }
    }
  }, []);

  /*useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;

      if(currentScrollY > 100) {
        setShowFloatNav(true)
        //document.getElementById("rensa-navbar").className = "nav-bar-custom navbar navbar-expand-lg navbar-dark fixed-top";
      } else {
        setShowFloatNav(false)
        //document.getElementById("rensa-navbar").className = "nav-bar-custom-start navbar navbar-expand-lg navbar-dark";
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);*/

  /*const handleNavClick = (eventKey) => {
    const yOffset = -100; 
    const element = eval(eventKey).current;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});
  }*/

  const handleCloseMessage = () => setShowMessage(false);

  /*const _handleNext = (currentIndex) => {
    setCurrentIndex(currentIndex + 1);
  };
  const _handleComplete = () => {};
  */

  return (
    <div>
      <Modal show={showMessage} onHide={handleCloseMessage}>
        <Modal.Header style={{ background: "#fff" }} closeButton>
          <Modal.Title style={{ color: "#000" }}>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", background: "#fff" }}>{ message }</Modal.Body>
        <Modal.Footer style={{ color: "#000", background: "#fff" }}>
          <Button variant="secondary" onClick={handleCloseMessage}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPrivacy} size="lg" onHide={ () => setShowPrivacy(false) }>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#000", paddingLeft: "34px" }}>Aviso de privacidad</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", textAlign: "justify" }}>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
          Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ () => setShowPrivacy(false) }>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
      
      <Navbar className='main-navbar' fixed="top">
        <Container>
          <Nav className="justify-content-center" style={{ flex: 1}}>
            
            <Container>
              <Row className="align-items-center">
                <Col md={ 3 } align='left'>
                  <a href='/sisesa'><img src={ logo } className='logo animate__animated animate__bounce animate__fadeIn' /></a>
                </Col>
                
                <Col md={ 6 } align='center' className='animate__animated animate__bounce animate__slideInUp'>
                  <div className='header-mail'>TORRES DE ENFRIAMIENTO</div>
                  <div className='header-tel'><TelephoneFill className='header-icon' />&nbsp; <a href='tel:525563857799' style={{ color: '#000' }}>+(52) 55 63 85 77 99</a> &nbsp;&nbsp;&nbsp;&nbsp; <Whatsapp className='header-icon' />&nbsp; <a href="https://wa.me/5215566942346" style={{ color: '#000' }}>55 6694 2346</a></div>
                  <div className='header-tel'><a href='mailto:ventas@sisesa.com' style={{ color: '#000' }}>ventas@sisesa.com</a></div>
                </Col>

                <Col md={ 3 } align='right'>
                  <img src={ cert } className='logo animate__animated animate__bounce animate__fadeIn' />
                </Col>
              </Row>
            </Container>

          </Nav>
        </Container>
      </Navbar>

      <Navbar className="nav-bar-custom-start" collapseOnSelect expand="lg" variant="dark" fixed="top">
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="justify-content-center" style={{ flex: 1}}>
              <Nav.Link href="/">
                <div className="nav-link-custom">INICIO</div>
              </Nav.Link>
              <Nav.Link href="/nosotros">
                <div className="nav-link-custom">NOSOTROS</div>
              </Nav.Link>
              <Nav.Link href="/torres">
                <div className="nav-link-custom">TIPOS DE TORRES</div>
              </Nav.Link>
              <Nav.Link href="/refacciones">
                <div className="nav-link-custom">REFACCIONES Y PARTES</div>
              </Nav.Link>
              <Nav.Link href="/galeria">
                <div className="nav-link-custom">GALERIA</div>
              </Nav.Link>
              {/*<Nav.Link href="/">
                <div className="nav-link-custom">BLOG</div>
              </Nav.Link>*/}
              <NavDropdown title="SERVICIOS" id="basic-nav-dropdown">
                <NavDropdown.Item href="/servicios"> Todos los servicios </NavDropdown.Item>
                <NavDropdown.Item href="/servicios?s=ree"> Reemplazo de elementos estructurales </NavDropdown.Item>
                <NavDropdown.Item href="/servicios"> Planes de mantenimiento </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/contacto">
                <div className="nav-link-custom">CONTACTO</div>
              </Nav.Link>

              <Nav.Link>
                <Linkedin className='icon-social' onClick={ () => window.open( linkedLink ) }/>
              </Nav.Link>
              <Nav.Link>
                <Instagram className='icon-social' onClick={ () => window.open( instaLink ) }/>
              </Nav.Link>
              {/* <Nav.Link>
                <Facebook className='icon-social' onClick={ () => window.open( faceLink ) }/>
              </Nav.Link>
              <Nav.Link>
                <Youtube className='icon-social' onClick={ () => window.open( youtubeLink ) }/>
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Navbar className="nav-bar-custom-start-mob" collapseOnSelect expand="lg" variant="light" fixed="top">
        <Container>
          <Navbar.Brand href="#home">
            <a href='/sisesa'><img src={ logo } className='logo animate__animated animate__bounce animate__fadeIn' /></a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="justify-content-center" style={{ flex: 1}}>
              <Nav.Link align="center">
                <div className='header-mail'>TORRES DE ENFRIAMIENTO</div>
                <div className='header-tel'>Tel: <a href='tel:525563857799' style={{ color: '#000' }}>52 55 63 85 77 99</a> / <a href='tel:5563857708' style={{ color: '#000' }}>55 63 85 77 08</a></div>
                <div className='header-tel'><a href='mailto:ventas@sisesa.com' style={{ color: '#000' }}>ventas@sisesa.com</a></div>
              </Nav.Link>
              <Nav.Link href="/">
                <div className="nav-link-custom">INICIO</div>
              </Nav.Link>
              <Nav.Link href="/nosotros">
                <div className="nav-link-custom">NOSOTROS</div>
              </Nav.Link>
              <Nav.Link href="/torres">
                <div className="nav-link-custom">TIPOS DE TORRES</div>
              </Nav.Link>
              <Nav.Link href="/refacciones">
                <div className="nav-link-custom">REFACCIONES Y PARTES</div>
              </Nav.Link>
              <Nav.Link href="/galeria">
                <div className="nav-link-custom">GALERIA</div>
              </Nav.Link>
              {/*<Nav.Link href="/">
                <div className="nav-link-custom">BLOG</div>
              </Nav.Link>*/}
              <NavDropdown title="SERVICIOS" id="basic-nav-dropdown">
                <NavDropdown.Item href="/servicios"> Todos los servicios </NavDropdown.Item>
                <NavDropdown.Item href="/servicios?s=ree"> Reemplazo de elementos estructurales </NavDropdown.Item>
                <NavDropdown.Item href="/servicios"> Planes de mantenimiento </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/contacto">
                <div className="nav-link-custom">CONTACTO</div>
              </Nav.Link>

              <Nav.Link>
                <Facebook className='icon-social' onClick={ () => window.open( faceLink ) }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Linkedin className='icon-social' onClick={ () => window.open( linkedLink ) }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Instagram className='icon-social' onClick={ () => window.open( instaLink ) }/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Youtube className='icon-social' onClick={ () => window.open( youtubeLink ) }/>
              </Nav.Link>
              <Nav.Link align="center">
                <img src={ cert } className='logo animate__animated animate__bounce animate__fadeIn' />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Home /> } />
          <Route path="/nosotros" element={ <Us /> } />
          <Route path="/torres" element={ <Towers /> } />
          <Route path="/servicios" element={ <Services /> } />
          <Route path="/contacto" element={ <Contact /> } />
          <Route path="/galeria" element={ <Galeria /> } />
          <Route path="/refacciones" element={ <Spareparts /> } />
        </Routes>
      </BrowserRouter>

      <div className="footer full-container">
        <Container style={ !isMobile ? { width: "90%" } : { width: "100%" } }>
          <Row className="align-items-center">
            <Col md={ 4 } xs={ 12 } align="center" >
              <img src={ logo_b } width="200" height="auto"/>
            </Col>
            <Col md={ 4 } xs={ 12 } align="center">
              <div className='footer-text' onClick={ () => setShowPrivacy(true) }>
                Aviso de Privacidad
              </div>
            </Col>
            <Col md={ 4 } xs={ 12 } align="center">
              <div>
                  <div className='header-tel' style={{ color: '#fff' }}>Tel: <a href='tel:525563857799' style={{ color: '#fff' }}>+(52) 55 63 85 77 99</a> / <a href='tel:5563857708' style={{ color: '#fff' }}>+(52) 55 63 85 77 08</a></div>
                  <div className='header-mail' style={{ color: '#fff' }}><a href='mailto:ventas@sisesa.com' style={{ color: '#fff' }}>ventas@sisesa.com</a></div>
              </div>
              <div>
                <Instagram className='footer-icon' onClick={ () => window.open( instaLink ) }/>&nbsp;&nbsp;
                <Linkedin className='footer-icon' onClick={ () => window.open( linkedLink ) }/>&nbsp;&nbsp;
                {/*<Facebook className='footer-icon' onClick={ () => window.open( faceLink ) }/>&nbsp;&nbsp;
                <Youtube className='footer-icon' onClick={ () => window.open( youtubeLink ) }/>*/}
              </div>
            </Col>
          </Row>
        </Container>        
      </div>
    </div>
  );
}

export default App;
