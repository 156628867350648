import frp1 from './img/refacciones/frp1.jpg'
import frp2 from './img/refacciones/frp2.jpg'
import frp3 from './img/refacciones/frp3.jpg'
import frp4 from './img/refacciones/frp4.jpg'
import madera1 from './img/refacciones/madera1.jpg'
import madera2 from './img/refacciones/madera2.jpg'
import madera3 from './img/refacciones/madera3.jpg'
import ventilador1 from './img/refacciones/ventilador1.jpg'
import ventilador2 from './img/refacciones/ventilador2.jpg'
import chimenea1 from './img/refacciones/chimenea1.jpg'
import chimenea2 from './img/refacciones/chimenea2.jpg'
import chimenea3 from './img/refacciones/chimenea3.jpg'
import motores1 from './img/refacciones/motores1.jpg'
import flechas1 from './img/refacciones/flechas1.jpg'
import soporte1 from './img/refacciones/soporte1.jpg'
import insertos1 from './img/refacciones/insertos1.jpg'
import insertos2 from './img/refacciones/insertos2.jpg'
import laminar1 from './img/refacciones/laminar1.jpg'
import hibrido1 from './img/refacciones/hibrido1.jpg'
import salpiqueo1 from './img/refacciones/salpiqueo1.jpg'
import salpiqueo2 from './img/refacciones/salpiqueo2.jpg'
import salpiqueo3 from './img/refacciones/salpiqueo3.jpg'
import eliminadores1 from './img/refacciones/eliminadores1.jpg'
import eliminadores2 from './img/refacciones/eliminadores2.jpg'
import eliminadores3 from './img/refacciones/eliminadores3.jpg'
import valvulas1 from './img/refacciones/valvulas1.jpg'
import tuberias1 from './img/refacciones/tuberias1.jpg'
import tuberias2 from './img/refacciones/tuberias2.jpg'
import valvulas2 from './img/refacciones/valvulas2.jpg'
import valvulas3 from './img/refacciones/valvulas3.jpg'
import valvulas4 from './img/refacciones/valvulas4.jpg'
import valvulas5 from './img/refacciones/valvulas5.jpg'
import juntas1 from './img/refacciones/juntas1.jpg'
import juntas2 from './img/refacciones/juntas2.jpg'
import mallas1 from './img/refacciones/mallas1.jpg'
import conectores1 from './img/refacciones/conectores1.jpg'
import tornilleria1 from './img/refacciones/tornilleria1.jpg'
import espreas1 from './img/refacciones/espreas1.jpg'
import espreas2 from './img/refacciones/espreas2.jpg'
import espreas3 from './img/refacciones/espreas3.jpg'
import espreas4 from './img/refacciones/espreas4.jpg'
import persianas1 from './img/refacciones/persianas1.jpg'
import persianas2 from './img/refacciones/persianas2.jpg'
import persianas3 from './img/refacciones/persianas3.jpg'
import persianas4 from './img/refacciones/persianas4.jpg'
import persianas5 from './img/refacciones/persianas5.jpg'

export const general_tools = [
    {
        name: 'PERFILES DE FIBRA DE VIDRIO REFORZADOS (FRP)',
        category: 'PERFILES DE FIBRA DE VIDRIO REFORZADOS (FRP)',
        images: [ frp1, frp2, frp3, frp4 ]
    }, {
        name: 'MADERA TRATADA QUIMICAMENTE',
        category: 'MADERA TRATADA QUIMICAMENTE',
        images: [ madera1, madera2, madera3 ]
    }, {
        name: 'VENTILADORES',
        category: 'EQUIPOS MECANICOS',
        images: [ ventilador1, ventilador2 ]
    }, {
        name: 'HIBRIDO',
        category: 'RELLENOS',
        images: [ hibrido1 ]
    }, {
        name: 'ELIMINADORES',
        category: 'ELIMINADORES',
        images: [ eliminadores1, eliminadores2, eliminadores3 ]
    }, {
        name: 'TUBERIAS',
        category: 'TUBERIAS',
        images: [ tuberias1, tuberias2 ]
    },
]

export const tools = [
    {
        name: 'PERFILES DE FIBRA DE VIDRIO REFORZADOS (FRP)',
        category: 'PERFILES DE FIBRA DE VIDRIO REFORZADOS (FRP)',
        images: [ frp1, frp2, frp3, frp4 ],
        desc: 'Ingeniería para las necesidades de pasamanos, barandillas, escaleras, piso y estructuras. El FRP es hasta un 70 % más liviano que el acero con una excepcional relación fuerza-peso. Resistente a la corrosión y requiere menos mantenimiento que el acero. Antideslizante y no conductivo, lo que proporciona una alternativa más segura que otros materiales.'
    }, {
        name: 'MADERA TRATADA QUIMICAMENTE',
        category: 'MADERA TRATADA QUIMICAMENTE',
        images: [ madera1, madera2, madera3 ],
        desc: 'Nuestro preservante CCA (Arsénico-Cobre Cromo) es un sistema de preservación soluble en agua, desarrollado para proteger a largo plazo la madera expuesta en estructuras al aire libre, contacto con el suelo y agua de mar y es aplicado a la madera en tratamientos de vacío presión.'
    }, {
        name: 'VENTILADORES',
        category: 'EQUIPOS MECANICOS',
        images: [ ventilador1, ventilador2 ],
        desc: 'Los ventiladores proporcionan aire en movimiento para enfriar el agua de condensación mediante evaporación.'
    }, {
        name: 'CHIMENEAS, CONOS VENTURI O CILINDROS',
        category: 'EQUIPOS MECANICOS',
        images: [ chimenea1, chimenea2, chimenea3 ],
        desc: 'Se utiliza para maximizar la eficiencia del ventilador, minimizando la recirculación del aire de descarga y garantiza baja turbulencia. Asi mismo resguarda el equipo mecánico.'
    }, {
        name: 'MOTORES',
        category: 'EQUIPOS MECANICOS',
        images: [ motores1 ],
        desc: 'Los ventiladores de la torre utilizan motores eléctricos. La alta humedad de la torre y el contacto con el agua, nieve, niebla y polvo, hacen necesario motores que puedan operar en condiciones severas.'
    }, {
        name: 'FLECHAS DE TRANSMISION',
        category: 'EQUIPOS MECANICOS',
        images: [ flechas1 ],
        desc: 'Carrete de acoplamiento construidas en una sola pieza, entre motor y reductor para transmisión de finalmente al ventilador.'
    }, {
        name: 'SOPORTES DE EQUIPOS MECANICOS',
        category: 'EQUIPOS MECANICOS',
        images: [ soporte1 ],
        desc: 'Está construido de una sola pieza tubular galvanizada por inmersión en caliente. Ha sido diseñado estructuralmente rígido para mantener el conjunto de motor-reductor-ventilador alineados y sin vibración.'
    }, {
        name: 'INSERTOS',
        category: 'EQUIPOS MECANICOS',
        images: [ insertos1, insertos2 ],
        desc: 'Este componente permite la fijación de la flecha de transmisión en los coples, tanto de lado ventilado como reductor y forma parte del equipo mecánico, asegurando que la flecha permanezca en todo momento en su lugar.'
    }, {
        name: 'LAMINAR',
        category: 'RELLENOS',
        images: [ laminar1 ],
        desc: 'Este tipo de relleno proporciona una mayor capacidad de enfriamiento para un mismo espacio ocupado, por lo que se ha impuesto progresivamente. La eficiencia depende de su capacidad para distribuir el agua en una fina película que fluye sobre grandes superficies, al objeto de proporcionar la máxima exposición del agua a la corriente de aire.'
    }, {
        name: 'HIBRIDO',
        category: 'RELLENOS',
        images: [ hibrido1 ],
        desc: 'La red o malla que forma este empaquetamiento es mucho más fina que la del relleno por salpicadura, el agua discurre a través del relleno en lugar de salpicar formando otras gotas.'
    }, {
        name: 'DE SALPIQUEO',
        category: 'RELLENOS',
        images: [ salpiqueo1, salpiqueo2, salpiqueo3 ],
        desc: 'Este tipo de relleno es diseñado para romper la masa de agua que cae por la torre en pequeñas gotas, de esta manera se consigue exponer una gran superficie de agua en contacto con el aire incrementando la transferencia de calor por conducción, convección y evaporación. Como ventaja de este tipo de empaquetamiento podemos destacar el gran tiempo de residencia que provoca en el agua dentro de la torre, ampliando el tiempo en el cual el agua está en contacto con el aire asi como el fácil mantenimiento y larga vida del equipo.'
    }, {
        name: 'ELIMINADORES',
        category: 'ELIMINADORES',
        images: [ eliminadores1, eliminadores2, eliminadores3 ],
        desc: 'La misión de este componente es la de retener las pequeñas gotas arrastradas por el aire que abandona la zona húmeda de la torre. Esta actuación provoca tres efectos positivos: disminuye las pérdidas de agua y la posibilidad de dispersión de la legionella que necesita las gotas para viajar, además de evitar posibles daños a equipos adyacentes.'
    }, {
        name: 'TUBERIAS',
        category: 'TUBERIAS',
        images: [ tuberias1, tuberias2 ],
        desc: 'La fabricación de tuberias ya sea en acero o en fibra de vidrio, es parte de los componentes elementales de nuestros equipos para la circulación o la distribución de agua.'
    }, {
        name: 'VALVULAS DE CONTROL DE FLUJO',
        category: 'TUBERIAS',
        images: [ valvulas1, valvulas2, valvulas3, valvulas4, valvulas5 ],
        desc: 'Nuestras válvulas regulan la presion de agua entrante en los depósitos de agua caliente, ya que se deberá tener el flujo controlado en su torre.'
    }, {
        name: 'JUNTAS DE EXPANSION',
        category: 'TUBERIAS',
        images: [ juntas1, juntas2 ],
        desc: 'Este elemento flexible formado por uno o más ondas, esta diseñado para resistir la presión interior del agua a temperatura de trabajo, y suficientemente flexible para absorber los movimientos que se presenten.'
    }, {
        name: 'MALLAS',
        category: 'ACERO INOXIDABLE',
        images: [ mallas1 ],
        desc: 'En este componente se coloca el relleno de salpiqueo, contiene secciones uniformemente distribuidas y se calculan en referencia al rendimiento de su torre. Están fabricadas en acero inoxidable, lo cual asegura su vida útil.'
    }, {
        name: 'CONECTORES',
        category: 'ACERO INOXIDABLE',
        images: [ conectores1 ],
        desc: 'Dichos elementos permiten la unión en la estructura de la torre, son fabricados en acero inoxidable y aportan rigidez a los equipos.'
    }, {
        name: 'TORNILLERIA',
        category: 'ACERO INOXIDABLE',
        images: [ tornilleria1 ],
        desc: 'Suministramos tornillería en general, de acero inoxidable, ya que nuestros equipos son integrados uno a uno por medio de espárragos, tornillos, arandelas, tuercas e incluso clavos de potencia.'
    }, {
        name: 'ESPREAS, ASPERSORES O CUERPOS DE DISTRIBUCION',
        category: 'ESPREAS, ASPERSORES O CUERPOS DE DISTRIBUCION',
        images: [ espreas1, espreas2, espreas3, espreas4 ],
        desc: 'Este elemento permite una mejor distribución del agua en el relleno, logrando una mejor eficiencia y desempeño térmico en su equipo.'
    }, {
        name: 'LOUVERS/PERSIANAS',
        category: 'LOUVERS/PERSIANAS',
        images: [ persianas1, persianas2, persianas3, persianas4, persianas5 ],
        desc: 'Láminas opacas de poliéster, fabricadas para soportar ambientes de extrema corrosión, altamente recomendadas como cubiertas y muros para torres de enfriamiento. El laminado cuenta con clasificación de propagación de flama en muchos de los casos. Dicho elemento es el revestimiento de su equipo.'
    },
]

/*export const sections_tools_a = [
    {
        name: 'PERFILES DE FIBRA DE VIDRIO REFORZADOS (FRP)',
        category: 'PERFILES DE FIBRA DE VIDRIO REFORZADOS (FRP)',
        images: [ frp1, frp2, frp3, frp4 ]
    }
]

export const sections_tools_b = [
    {
        name: 'MADERA TRATADA QUIMICAMENTE',
        category: 'MADERA TRATADA QUIMICAMENTE',
        images: [ madera1, madera2, madera3 ]
    }
]

export const sections_tools_c = [
    {
        name: 'VENTILADORES',
        category: 'EQUIPOS MECANICOS',
        images: [ ventilador1, ventilador2 ]
    }, {
        name: 'CHIMENEAS, CONOS VENTURI O CILINDROS',
        category: 'EQUIPOS MECANICOS',
        images: [ chimenea1, chimenea2, chimenea3 ]
    }, {
        name: 'MOTORES',
        category: 'EQUIPOS MECANICOS',
        images: [ motores1 ]
    }, {
        name: 'FLECHAS DE TRANSMISION',
        category: 'EQUIPOS MECANICOS',
        images: [ flechas1 ]
    }, {
        name: 'SOPORTES DE EQUIPOS MECANICOS',
        category: 'EQUIPOS MECANICOS',
        images: [ soporte1 ]
    }, {
        name: 'INSERTOS',
        category: 'EQUIPOS MECANICOS',
        images: [ insertos1 ]
    },
]*/