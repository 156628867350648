import { useState, useEffect, useRef } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'

import { AnimationOnScroll } from 'react-animation-on-scroll';

import ToolsSisesa from './ToolsSisesa'

import "./styles.css";

import towers01 from './img/home/towers01.jpg'
import towers02 from './img/home/towers02.jpg'
import towers03 from './img/home/towers03.jpg'
import towers04 from './img/home/towers04.jpg'
import towers05 from './img/home/towers05.jpg'
import towers06 from './img/home/towers06.jpg'
import towers07 from './img/home/towers07.jpg'
import towers08 from './img/home/towers08.jpg'

import 'animate.css';
import './App.css';

function Towers() {
  return (
    <div>
      <div className='navbar-space'></div>

      <div className="animate__animated animate__bounce animate__fadeIn">
        <Container className="section" style={{ paddingTop: '40px'  }}>
          <Row>
            <div className="section-title" align="center">TIPOS DE TORRES DE ENFRIAMIENTO</div>
          </Row>
          <br/>
          <Row className="align-items-center">
            <Col md={ 12 } xs={ 12 }>            
              <div className='mission-title' style={{ color: '#000' }}>
                ¿QUÉ ES UNA TORRE DE ENFRIAMIENTO?
              </div>
              <div className="section-desc" align="center">
                <div>Equipo que se usa para enfriar agua en grandes volúmenes, generando un ahorro en el uso elemental de este vital liquido.</div>
                <div>Algunos procesos requieren de un medio de disipación de calor para evitar problemas con el proceso mismo.</div>
              </div>
            </Col>
            {/*<Col md={ 6 } xs={ 12 }>
              <div className="section-desc">
                <img src={ nosotros } className='us-img'/>
              </div>
            </Col>*/}
          </Row>
          <Row className="align-items-center">
            <Col>
            </Col>
          </Row>
        </Container>

        <div className='animate__animated animate__bounce animate__fadeIn'>
        <Container>
          <Row className='section-towers'>
            <Col md={ 6 }>
              <div className="container-depts">
                <div className="section-desc">
                  <img src={ towers01 } className='us-img'/>
                </div>
                <div className='mission-title' style={{ color: '#000' }}>
                  FLUJO CRUZADO  (XF)
                </div>
                <div className="grl-text" align="center">
                  <div>Se les nombra de esta forma porque el aire entra de manera horizontal en toda la cara de la torre, mientras que el agua contenida en una charola con orificios cae de manera vertical, cruzándose ambos en el área del relleno, que es donde se realiza el intercambio calórico, por eso se llama flujo cruzado.</div>
                </div>
              </div>
            </Col>
            <Col md={ 6 }>
              <div className="container-depts">
                <div className="section-desc">
                  <img src={ towers04 } className='us-img'/>
                </div>
                <div className='mission-title' style={{ color: '#000' }}>
                  CONTRA FLUJO (CF)
                </div>
                <div className="grl-text" align="center">
                  <div>En las torres de contra flujo, el aire entra por la parte inferior de la torre y se mueve verticalmente hacia arriba atravesando la zona de relleno, mientras que el agua del proceso se rocía desde arriba del area del relleno y se distribuye en gotas en flujo descendente.</div>
                  <br/>
                  <div>Se le denomina contraflujo porque en el área de relleno se cruzan el aire y el agua para realizar el intercambio calórico. En estas torres es necesario un sistema de rocío a presión.</div>
                </div>
              </div>
            </Col>
            <Col md={ 6 }>
              <div className="container-depts">
                <div className="section-desc">
                  <Carousel interval={ 5000 } pause={ false } controls={ false } indicators={ false }>
                    <Carousel.Item>
                      <img src={ towers07 } className='us-img'/>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img src={ towers08 } className='us-img'/>
                    </Carousel.Item>
                  </Carousel>
                </div>
                <div className='mission-title' style={{ color: '#000' }}>
                  SERIES 10, 15 y 18
                </div>
                <br/>
              </div>
            </Col>
            <Col md={ 6 }>
              <div className="container-depts">
                <div className="section-desc">
                  <img src={ towers06 } className='us-img'/>
                </div>
                <div className='mission-title' style={{ color: '#000' }}>
                  MODULARES, CONSTRUIDAS EN CAMPO
                </div>
                <br/>
              </div>
            </Col>
          </Row>
          <br/>
          <br/>
          <Row>
            <Col md={ 12 }>
              <div className="container-depts">
                <div className='mission-title' style={{ color: '#000' }}>
                  TORRES ARMADAS EN NUESTRAS INSTALACIONES (MODULARES O DE PAQUETE)
                </div>
                <div className="grl-text" align="center" style={{ padding: '0' }}>
                  <br/>
                  <div>Las torres modulares son pequeñas y llegan prácticamente armadas con el cliente. Son fabricadas en serie con un tamaño y capacidad especifica, se instalan para trabajar en serie cubriendo diferentes flujos dependiendo de la necesidad del cliente, por ejemplo, en lugar de una torre industrial se instalan 4 torres modulares. Sin embargo, el costo del suministro y de la operación es mayor si se toma la opcion de las torres modulares.</div>
                  <br/>
                  <div>Una de las ventajas de las torres modulares es, que su tamaño permite ubicarla en diferentes áreas de la empresa y unir sus flujos.</div>
                </div>
                <br/>
                <div className="section-desc">
                <Carousel interval={ 5000 } pause={ false }>
                  <Carousel.Item>
                    <img src={ towers03 } className='us-img'/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={ towers05 } className='us-img'/>
                  </Carousel.Item>
                </Carousel>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        </div>

        <ToolsSisesa />

      </div>

    </div>
  );
}

export default Towers;
