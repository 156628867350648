import { useState, useEffect, useRef } from 'react';
import axios from 'axios'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Carousel from 'react-bootstrap/Carousel'

import { tools } from './tools'

import "./styles.css";
import 'animate.css';
import './App.css';
import { Geo } from 'react-bootstrap-icons';

function Spareparts() {

  useEffect(() => {
  });

  return (
    <div>

      <div className='navbar-space'></div>

      <div className="animate__animated animate__bounce animate__fadeIn">
        <Container className="section">
          <Row>
            <div className="section-title" align="center">REFACCIONES Y PARTES</div>
          </Row>
          <br/>
          <br/>
          
          <Container>
            <Row>
              { tools.map(item => (
                <Col md={ 4 } className="ref-col">   
                  <div className="parts-hover">               
                    <div>
                      <Carousel controls={ false } indicators={ false } pause={ false } fade={ true }>
                        {item.images.map(ig => (
                          <Carousel.Item>
                            <img className='grid-img hover-part' src={ ig } />
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    </div>
                    <div className="overlay">
                      <div className='overlay-color'>
                        <div className="overlay-text">
                          <div className="overlay-title">{ item.name }</div>
                          <div className="text-space"></div>
                          <div className="part-desc">{ item.desc }</div>
                          <br/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='title-item'>{ item.name }</div>
                  <div className='category-item'>{ item.category }</div>
                </Col>
              ))}
            </Row>
          </Container>

          {/*
          <Container>
            <Row>
              <div className='mission-title' style={{ color: '#000' }}>
                PERFILES DE FIBRA DE VIDRIO REFORZADOS (FRP)
              </div>
              <br/>
            </Row>
            <Row>
              { sections_tools_a.map(item => (
                <Col md={ 3 } className="ref-col">                  
                  <div>
                    <Carousel controls={ false } indicators={ false } pause={ false } fade={ true }>
                      {item.images.map(ig => (
                        <Carousel.Item>
                          <img className='grid-img' src={ ig } />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>

                  <div className='title-item'>{ item.name }</div>
                  <div className='category-item'>{ item.category }</div>
                </Col>
              ))}
            </Row>
          </Container>

          <Container>
            <Row>
              <div className='mission-title' style={{ color: '#000' }}>
                MADERA TRATADA QUIMICAMENTE
              </div>
              <br/>
            </Row>
            <Row>
              { sections_tools_b.map(item => (
                <Col md={ 3 } className="ref-col">                  
                  <div>
                    <Carousel controls={ false } indicators={ false } pause={ false } fade={ true }>
                      {item.images.map(ig => (
                        <Carousel.Item>
                          <img className='grid-img' src={ ig } />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>

                  <div className='title-item'>{ item.name }</div>
                  <div className='category-item'>{ item.category }</div>
                </Col>
              ))}
            </Row>
          </Container>

          <Container>
            <Row>
              <div className='mission-title' style={{ color: '#000' }}>
                EQUIPOS MECANICOS
              </div>
              <br/>
            </Row>
            <Row>
              { sections_tools_c.map(item => (
                <Col md={ 3 } className="ref-col">                  
                  <div>
                    <Carousel controls={ false } indicators={ false } pause={ false } fade={ true }>
                      {item.images.map(ig => (
                        <Carousel.Item>
                          <img className='grid-img' src={ ig } />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>

                  <div className='title-item'>{ item.name }</div>
                  <div className='category-item'>{ item.category }</div>
                </Col>
              ))}
            </Row>
          </Container> */}

        </Container>
      </div>

    </div>
  );
}

export default Spareparts;
